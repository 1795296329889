import React, { useEffect, useRef, useState } from "react";
import UserImage from "../images/user/219988.png";
import BGImage from "../images/user/pxfuel.jpg";
import styled from "styled-components";
import { FaCheckCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import JoditEditor from "jodit-react";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import PostImage from "../images/books/book.jpg";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import { useTranslation } from "react-i18next";
import UploadImg from "../components/uploadImage";
import axios from "axios";
import { TbPointFilled } from "react-icons/tb";
import { MdEditSquare } from "react-icons/md";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axiosInstance from "../api/axios";

const Profile = () => {
  const [item1, setItem1] = useState(true);
  const [item2, setItem2] = useState(false);
  const [item3, setItem3] = useState(false);
  const [item4, setItem4] = useState(false);
  const UserInformation = useSelector((user) => user?.user?.user?.user);
  const postImageId = useSelector(
    (imageId) => imageId?.uploadImage?.uploadImage
  );
  const [content, setContent] = useState("");
  const { t } = useTranslation();
  const [createPostTitle, setCreatePostTitle] = useState("");
  const editor = useRef(null);
  const [createdPosts, setCreatedPosts] = useState([]);
  localStorage.setItem("user_id", UserInformation?.id);
  const [postDelete, setPostDelete] = useState(false);

  function CreatePost() {
    const formData = new FormData();

    formData.append("Title", createPostTitle);
    formData.append("Content", content);
    formData.append("UserId", UserInformation?.id);
    formData.append("CoverImageId", postImageId);

    axiosInstance
      .post(`/api/Post`, formData)
      .then((res) => {
        toast.success("Postni yaratish muofiqiyatli bajarildi");
        setContent("");
        handle1();
      })
      .catch((err) => {
        toast.error("Xatolik yuz berdi!");
      });
  }

  function deletePost(itemId) {
    axiosInstance
      .delete(`/api/Post/${itemId}`)
      .then((res) => {
        toast.success("Postni o'chirish muofiqiyatli bajarildi");
        setPostDelete(!postDelete);
      })
      .catch((err) => {
        toast.error("Xatolik yuz berdi!");
      });
  }

  useEffect(() => {
    axiosInstance
      .get(`/api/Post?UserId=${localStorage.getItem("user_id")}`)
      .then((res) => {
        setCreatedPosts(res?.data?.result);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [UserInformation?.id, postDelete]);

  const onDrop = (acceptedFiles) => {
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const config = {
    // Jodit options/configuration goes here
    // For example:
    height: 300,
  };

  function handle1() {
    setItem1(true);
    setItem2(false);
    setItem3(false);
    setItem4(false);
  }

  function handle2() {
    setItem1(false);
    setItem2(true);
    setItem3(false);
    setItem4(false);
  }

  function handle3() {
    setItem1(false);
    setItem2(false);
    setItem3(true);
    setItem4(false);
  }

  function handle4() {
    setItem1(false);
    setItem2(false);
    setItem3(false);
    setItem4(true);
  }

  return (
    <div>
      <Navbar />
      <div className="container">
        <UserInfo>
          <BGImageComponent>
            <img src={BGImage} alt="bg_image" />
          </BGImageComponent>
          <User>
            <UserImageComponent>
              <UserImageBox>
                <img src={UserImage} alt="user_image" />
              </UserImageBox>
            </UserImageComponent>
            <div>
              <UserName>
                <h5>
                  {UserInformation ? UserInformation?.firstName : "Topilmadi"}{" "}
                  {UserInformation ? UserInformation?.lastName : "Topilmadi"}
                </h5>
              </UserName>
              <UserActiveBox>
                <p>
                  <FaCheckCircle /> {t("ACTIVE")}
                </p>
              </UserActiveBox>
            </div>
            <UserButtons>
              <button>{t("EDIT_PROFILE")}</button>
            </UserButtons>
          </User>
        </UserInfo>
        <UserFunctions>
          <UserFuncBUttons>
            <button
              style={{
                background: item1 ? "green" : "lightblue",
                color: item1 ? "#fff" : "#000",
              }}
              onClick={handle1}
            >
              {t("USER_INFORMATION")}
            </button>
            {UserInformation?.role <5? (
              <>
                <button
                  style={{
                    background: item2 ? "green" : "lightblue",
                    color: item2 ? "#fff" : "#000",
                  }}
                  onClick={handle2}
                >
                  Siz yaratgan postlar
                </button>
                <button
                  style={{
                    background: item3 ? "green" : "lightblue",
                    color: item3 ? "#fff" : "#000",
                  }}
                  onClick={handle3}
                >
                  Post yaratish
                </button>
              </>
            ) : null}
            <button
              style={{
                background: item4 ? "green" : "lightblue",
                color: item4 ? "#fff" : "#000",
              }}
              onClick={handle4}
            >
              {t("ORDER_BOOKS_HEADER")}
            </button>
          </UserFuncBUttons>
          <ActiveItems>
            {item1 && (
              <AtiveItem>
                <UserInfoBox>
                  <UserInfoHeader>
                    <h6>{t("U_INFORMATION")}</h6>
                  </UserInfoHeader>
                  <UserInfoMain>
                    <div>
                      <b>{t("NAME")}</b>
                      <p>
                        {UserInformation?.firstName
                          ? UserInformation?.firstName
                          : "Topilmadi"}
                      </p>
                    </div>
                    <div>
                      <b>{t("LAST_NAME")}</b>
                      <p>
                        {UserInformation?.lastName
                          ? UserInformation?.lastName
                          : "Topilmadi"}
                      </p>
                    </div>
                    <div>
                      <b>{t("GROUP")}</b>
                      <p>
                        {UserInformation?.group
                          ? UserInformation?.group
                          : "Topilmadi"}
                      </p>
                    </div>
                    <div>
                      <b>{t("HEMIS_ID")}</b>
                      <p>
                        {UserInformation?.hemisId
                          ? UserInformation?.hemisId
                          : "Topilmadi"}
                      </p>
                    </div>
                  </UserInfoMain>
                </UserInfoBox>
                <UserInfoBox>
                  <UserInfoHeader>
                    <h6>{t("CONTACT")}</h6>
                  </UserInfoHeader>
                  <UserInfoMain>
                    <div>
                      <b>{t("PHONE")}</b>
                      <p>
                        {UserInformation?.phone
                          ? UserInformation?.phone
                          : "Topilmadi"}
                      </p>
                    </div>
                    <div>
                      <b>{t("EMAIL")}</b>
                      <p>
                        {UserInformation?.email
                          ? UserInformation?.email
                          : "Topilmadi"}
                      </p>
                    </div>
                  </UserInfoMain>
                </UserInfoBox>
                <UserInfoBox>
                  <UserInfoHeader>
                    <h6>
                      <p>{t("ORDER_BOOKS")}</p>
                      <p>0</p>
                    </h6>
                  </UserInfoHeader>
                  <UserInfoMain>
                    <b>{t("NO_ORDER_BOOK")}</b>
                    <div>
                      <b>
                        {" "}
                        <TbPointFilled />
                        Chiziqli Algebra
                      </b>
                      <p>12/06/2023 - 12/19/2023</p>
                    </div>
                  </UserInfoMain>
                </UserInfoBox>
              </AtiveItem>
            )}
            {item2 && (
              <AtiveItem>
                <PostGrid>
                  {createdPosts ? (
                    createdPosts.map((item) => {
                      return (
                        <PostItem>
                          <Link
                            style={{ color: "#000" }}
                            to={`/post/${item?.id}`}
                          >
                            <PostImageBox>
                              <img
                                src={`${process.env.REACT_APP_BACKEND_URL}api/file/downloadimagefile/${item?.coverImage?.id}/middle`}
                                alt="post_image"
                              />
                            </PostImageBox>
                            <PostInformation>
                              <p>
                                {new Date(item?.createdAt).toLocaleDateString()}
                              </p>
                              <h5>{item?.title}</h5>
                            </PostInformation>
                          </Link>
                          <UpdateButton>
                            <button
                              onClick={() => {
                                handle3();
                              }}
                            >
                              <MdEditSquare />
                            </button>
                          </UpdateButton>
                          <DeleteButton>
                            <button onClick={() => deletePost(item?.id)}>
                              <MdDelete />
                            </button>
                          </DeleteButton>
                        </PostItem>
                      );
                    })
                  ) : (
                    <p>Yaratilgan postlar yuq</p>
                  )}
                </PostGrid>
              </AtiveItem>
            )}
            {item3 && (
              <AtiveItem>
                <CreatePostContainer>
                  <PostTitle>
                    <input
                      onChange={(e) => setCreatePostTitle(e.target.value)}
                      type="text"
                      placeholder="Post Sarlovhasi"
                    />
                  </PostTitle>
                  <UploadImg type="main" />
                  <div className="jodit">
                    <JoditEditor
                      ref={editor}
                      value={content}
                      onBlur={(newContent) => setContent(newContent)}
                      config={config}
                    />
                  </div>
                  <UploadImg type="content" />
                  <button onClick={CreatePost}>Post yaratish</button>
                </CreatePostContainer>
              </AtiveItem>
            )}
            {item4 && (
              <AtiveItem>
                <OrderBookGrid>
                  <OrderBookItem>
                    <OrderBookImage>
                      <img src={PostImage} alt="order_book_image" />
                    </OrderBookImage>
                    <OrderBookTime>
                      <p>05/05/2024</p>
                    </OrderBookTime>
                    <OrderBookTitle>
                      <h5>Book Title</h5>
                    </OrderBookTitle>
                    <OrderBookBtn>
                      <Link to="/">Batafsil...</Link>
                    </OrderBookBtn>
                  </OrderBookItem>
                </OrderBookGrid>
              </AtiveItem>
            )}
          </ActiveItems>
        </UserFunctions>
      </div>
      <Footer />
    </div>
  );
};

const UpdateButton = styled.div`
  button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    padding: 5px 8px;
    border-bottom-left-radius: 5px;
    background-color: green;
    color: #fff;
  }
`;

const DeleteButton = styled.div`
  button {
    position: absolute;
    bottom: 0;
    left: 0;
    border: none;
    padding: 5px 8px;
    border-top-right-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1);
    background-color: red;
    color: #fff;

    svg {
      font-size: 18px;
    }
  }
`;

const UserInfo = styled.div`
  width: 100%;
  box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
  margin-top: 80px;

  @media (max-width: 576px) {
    margin-top: 50px;
  }
`;

const BGImageComponent = styled.div`
  width: 100%;
  height: 200px;
  overflow: hidden;

  img {
    width: 100%;
    object-position: center;
  }
`;

const UserName = styled.div`
  @media (max-width: 576px) {
    h5 {
      font-size: 16px;
      padding-left: 5px;
      padding-bottom: 0px;
    }
  }
`;

const UserActiveBox = styled.div`
  @media (max-width: 576px) {
    p {
      margin-top: 0;
      padding-left: 5px;
    }
  }
`;

const User = styled.div`
  width: 100%;
  height: 100px;
  display: grid;
  grid-template-columns: 2fr 5fr 2fr;
`;

const UserImageComponent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const UserImageBox = styled.div`
  width: 150px;
  height: 150px;
  border: 2px solid #fff;
  transform: translateY(-50%);
  border-radius: 50%;

  @media (max-width: 576px) {
    width: 100px;
    height: 100px;
    margin-left: 10px;
  }

  img {
    width: 100%;
  }
`;

const UserButtons = styled.div`
  padding-top: 10px;

  button {
    border: none;
    outline: none;
    padding: 5px 12px;
    border-radius: 10px;
    background-color: lightblue;
  }

  @media (max-width: 576px) {
    button {
      padding: 5px 8px;
      font-size: 12px;
    }
  }
`;

const UserFunctions = styled.div`
  width: 100%;
  margin-top: 20px;
  box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
`;

const UserFuncBUttons = styled.div`
  display: flex;
  height: 100px;
  align-items: center;

  button {
    border: none;
    outline: none;
    padding: 5px 12px;
    background-color: lightblue;
    border-radius: 10px;
    margin: 0 5px;
  }

  @media (max-width: 576px) {
    button {
      padding: 5px 8px;
      font-size: 12px;
    }
  }
`;

const ActiveItems = styled.div`
  width: 100%;
  min-height: 500px;
  display: flex;
`;

const AtiveItem = styled.div`
  width: 1200px;
  min-height: 400px;
  display: flex;

  @media (max-width: 576px) {
    flex-wrap: wrap;
  }
`;

const UserInfoBox = styled.div`
  width: 350px;
  box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 10px;
`;

const UserInfoHeader = styled.div`
  width: 100%;
  color: #fff;
  padding: 10px;
  background-color: #333;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  h6 {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;

    p {
      margin: 0;
    }
  }
`;

const UserInfoMain = styled.div`
  width: 100%;
  padding: 10px;
`;

const CreatePostContainer = styled.div`
  width: 900px;
  margin: 0 auto;
  padding-bottom: 40px;

  button {
    border: none;
    outline: none;
    padding: 5px 8px;
    border-radius: 5px;
  }
`;

const PostTitle = styled.div`
  input {
    width: 100%;
    padding: 8px 10px;
    border-radius: 5px;
    border: none;
    outline: none;
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
  }
`;

const PostGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 20px;
  padding: 15px;
`;

const PostItem = styled.div`
  width: 100%;
  height: 300px;
  box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  position: relative;
`;

const PostImageBox = styled.div`
  width: 100%;
  height: 180px;
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  img {
    width: 100%;
    transition: all 0.5s;
  }
`;

const PostInformation = styled.div`
  width: 100%;
  height: 100px;
  padding: 0 5px;

  p {
    padding: 0;
    margin: 0;
  }

  h5 {
    margin-top: 3px;
    font-size: 16px;
  }

  a {
    position: absolute;
    bottom: 5px;
  }
`;

const OrderBookGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 15px;
  padding: 15px;

  @media (max-width: 576px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const OrderBookItem = styled.div`
  width: 100%;
  box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  border-radius: 10px;
  position: relative;

  @media (max-width: 576px) {
    width: 80%;
    margin: 0 auto;
  }
`;

const OrderBookImage = styled.div`
  width: 100%;
  height: 300px;
  overflow: hidden;
  transition: all 0.5s;
  overflow: hidden;

  &:hover {
    img {
      transform: scale(1.1);
    }
  }

  img {
    width: 100%;
    transition: all 0.5s;
  }

  @media (max-width: 576px) {
    height: 350px;
  }
`;

const OrderBookTime = styled.div`
  p {
    padding-left: 8px;
  }
`;

const OrderBookTitle = styled.div`
  h5 {
    font-size: 16px;
    padding-left: 8px;
  }
`;

const OrderBookBtn = styled.div`
  position: absolute;
  bottom: 0;

  a {
    color: #000;
    padding: 5px 8px;
  }
`;

export default Profile;
