import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import axios from "axios";
import axiosInstance from "../api/axios";



const SingleMenuScript = () => {
    const params = useParams();
    const [sinleSlave, setSingleSlave] = useState({});

    useEffect(() => {
        axiosInstance.get(`/api/Ancient/${params.id}`)
            .then(res => {
                setSingleSlave(res?.data?.result)
            })
            .catch(err => {
                console.log(err);
            })
    }, [])




    return (
        <div>
            <Navbar />
            <div className="container">
                <SingleMenuScriptBox>
                    <h1>Asar haqida</h1>
                    <div className='slave-single-page-grid'>
                        <div className='slave-single-page-item'>
                            <div className='slave-left'>
                                <b>Asarning nomi</b>
                            </div>
                            <div className='slave-right'>
                                <p>{sinleSlave?.work_name}</p>
                            </div>
                        </div>
                        <div className='slave-single-page-item'>
                            <div className='slave-left'>
                                <b>Muallifi</b>
                            </div>
                            <div className='slave-right'>
                                <p>{sinleSlave?.author}</p>
                            </div>
                        </div>
                        <div className='slave-single-page-item'>
                            <div className='slave-left'>
                                <b>Xattot</b>
                            </div>
                            <div className='slave-right'>
                                <p>{sinleSlave?.calligrapher}</p>
                            </div>
                        </div>
                        <div className='slave-single-page-item'>
                            <div className='slave-left'>
                                <b>Mavzusi</b>
                            </div>
                            <div className='slave-right'>
                                <p>{sinleSlave?.subject}</p>
                            </div>
                        </div>
                        <div className='slave-single-page-item'>
                            <div className='slave-left'>
                                <b>Tili</b>
                            </div>
                            <div className='slave-right'>
                                <p>{sinleSlave.language}</p>
                            </div>
                        </div>
                        <div className='slave-single-page-item'>
                            <div className='slave-left'>
                                <b>Ko'chirilgan yoki yozilgan sanasi</b>
                            </div>
                            <div className='slave-right'>
                                <p>{sinleSlave?.date_write}</p>
                            </div>
                        </div>
                        <div className='slave-single-page-item'>
                            <div className='slave-left'>
                                <b>Holati</b>
                            </div>
                            <div className='slave-right'>
                                <p>{sinleSlave?.status}</p>
                            </div>
                        </div>
                        <div className='slave-single-page-item'>
                            <div className='slave-left'>
                                <b>O'lchami</b>
                            </div>
                            <div className='slave-right'>
                                <p>{sinleSlave?.size}</p>
                            </div>
                        </div>
                        <div className='slave-single-page-item'>
                            <div className='slave-left'>
                                <b>Inventor raqami</b>
                            </div>
                            <div className='slave-right'>
                                <p>{sinleSlave.inventor_id}</p>
                            </div>
                        </div>
                        <div className='slave-single-page-item'>
                            <div className='slave-left'>
                                <b>Saqlanish joyi</b>
                            </div>
                            <div className='slave-right'>
                                <p>{sinleSlave?.place_saved}</p>
                            </div>
                        </div>
                        <div className='slave-single-page-item'>
                            <div className='slave-left'>
                                <b>Qo'shimcha ma'lumot</b>
                            </div>
                            <div className='slave-right'>
                                <p>{sinleSlave?.description ? sinleSlave?.description : "-"}</p>
                            </div>
                        </div>
                    </div>
                </SingleMenuScriptBox>
            </div>
            <Footer />
        </div>
    )
}

const SingleMenuScriptBox = styled.div`
    margin-top: 125px;

    h1 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 40px;
    }
`


export default SingleMenuScript;