import axiosInstance from "./axios";

const AuthService = {
    async getUser() {
        const {data} = await axiosInstance.get('/api/user/loginwithaccesstoken',{headers:{
            Authorization:`Bearer ${localStorage.getItem('token')}`
        }})
        return data
    }
}

export default AuthService;