import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { SiScpfoundation } from "react-icons/si";
import { TbDiscountCheck } from "react-icons/tb";
import { FaUserGraduate } from "react-icons/fa";
import { FaUserTie } from "react-icons/fa6";
import axios from "axios";
import { useTranslation } from "react-i18next";
import axiosInstance from "../api/axios";



const Information = () => {
    const [bookCount, setBookCount] = useState(0);
    const { t } = useTranslation();


    useEffect(() => {
        axiosInstance.get(`/api/Book?pageNumber=0&pageSize=10`)
            .then(res => {
                setBookCount(res?.data?.result?.totalCount)
            })
            .catch(err => {
                console.log(err)
            })
    })



    return (
        <InformationBox>
            <div className="container">
                <InformationGrid>
                    <InformationItem data-aos="zoom-in" data-aos-delay="0">
                        <InformationImgBox>
                            <h6>
                                <SiScpfoundation />
                            </h6>
                        </InformationImgBox>
                        <InformationCount>
                            <h4>2 768 927+</h4>
                        </InformationCount>
                        <InformationTitle>
                            <p>{t('INFORMATION1')}</p>
                        </InformationTitle>
                    </InformationItem>
                    <InformationItem data-aos="zoom-in" data-aos-delay="100">
                        <InformationImgBox>
                            <h6>
                                <TbDiscountCheck />
                            </h6>
                        </InformationImgBox>
                        <InformationCount>
                            <h4>{bookCount + '+'}</h4>
                        </InformationCount>
                        <InformationTitle>
                            <p>{t('INFORMATION2')}</p>
                        </InformationTitle>
                    </InformationItem>
                    <InformationItem data-aos="zoom-in" data-aos-delay="200">
                        <InformationImgBox>
                            <h6>
                                <FaUserGraduate />
                            </h6>
                        </InformationImgBox>
                        <InformationCount>
                            <h4>9206+</h4>
                        </InformationCount>
                        <InformationTitle>
                            <p>{t('INFORMATION3')}</p>
                        </InformationTitle>
                    </InformationItem>
                    <InformationItem data-aos="zoom-in" data-aos-delay="300">
                        <InformationImgBox>
                            <h6>
                                <FaUserTie />
                            </h6>
                        </InformationImgBox>
                        <InformationCount>
                            <h4>8150+</h4>
                        </InformationCount>
                        <InformationTitle>
                            <p>{t('INFORMATION4')}</p>
                        </InformationTitle>
                    </InformationItem>
                </InformationGrid>
            </div>
        </InformationBox>
    )
}


const InformationBox = styled.div`
    width: 100%;
    min-height: 300px;
`

const InformationGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    padding-top: 30px;
    padding-bottom: 30px;

    @media (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
    }
`

const InformationItem = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
`

const InformationImgBox = styled.div`
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(115deg, #f9ce34, #ee2a7b, #6228d7);

    h6 {
        margin: 0;
        padding: 0;
        font-size: 62px;
        color: #fff;

        svg {
            margin: 0;
            padding: 0;
        }
    }
`

const InformationCount = styled.div`
    h4 {
        padding-top: 10px;
        padding-bottom: 10px;
    }
`

const InformationTitle = styled.div`
    p {
        text-align: center;
    }
`

export default Information;