import React, { useState } from 'react'
import styled from 'styled-components'
import { useNavigate } from 'react-router-dom';

export default function MainSearch() {
    const [mainSearchValue, setMainSearchValue] = useState();
    const navigate = useNavigate();

    function handleMainSearch() {
        navigate(`/order-book/?title=${mainSearchValue}&tag=&Book_Author=&Publisher=&UDK=&ISBN_No=&Book_Inventory_No=&Publish_Date=&Category=1&pageNumber=0&pageSize=12`)
    }

  return (
    <MainSearchComp>
        <div className='container'>
            <MainSerachBox>
                <input onChange={(e) => setMainSearchValue(e.target.value)} className='form-control w-100 text-block border-end-0 border-start-0 border-top-0 border-bottom-0' type='text' placeholder='Search...' />
                <button onClick={handleMainSearch} className='btn btn-success'><i class='bx bx-search'></i></button>
            </MainSerachBox>
        </div>
    </MainSearchComp>
  )
}


const MainSearchComp = styled.div`
    width: 100%;
    padding: 15px;
    background-color: #f2f2f2;
`

const MainSerachBox = styled.div`
    display: grid;
    grid-template-columns: 11fr 1fr;
    grid-gap: 15px;
` 