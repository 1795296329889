import React, { useEffect, useState } from "react";
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import styled from "styled-components";
import { useParams } from "react-router-dom";
import axiosInstance from "../api/axios";




const SinglePosts = () => {
    const params = useParams();
    const [post, setPost] = useState({});

    useEffect(() => {
        axiosInstance.get(`/api/Post/${params?.id}`)
            .then(res => {
                setPost(res?.data?.result)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])




    return (
        <div>
            <div>
                <Navbar />
            </div>
            <div className="container">
                <SinglePostsBox>
                    <div>
                        <h1>{post?.title}</h1>
                    </div>
                    <SinglePostBoxContent dangerouslySetInnerHTML={{ __html: post?.content }}></SinglePostBoxContent>
                </SinglePostsBox>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}

const SinglePostsBox = styled.div`
    width: 100%;
    margin-top: 75px;
    padding: 20px;
`

const SinglePostBoxContent = styled.div`
    img {
        width: 100%;
    }
`

export default SinglePosts;