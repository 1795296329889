import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../api/axios";


const BackPageLogin = () => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();

    const Login = {
        login : login,
        password: password
    }


    function handleAdminLogin() {
        axiosInstance.post('/api/user/loginwithemail', Login)
            .then(res => {
                console.log(res?.data?.isSuccess);
                
                localStorage.setItem('role', res?.data?.result?.user?.role);
                if(res?.data?.isSuccess) {
                    navigate('/admin');
                } else {
                    navigate('/admin-login')
                }
            })
            .catch(err => {
                console.log(err)
            })
    }



    return (
        <BackPageLoginBox>
            <BackPageLoginContainer>
                <h3>Login va Parolni kiriting!</h3>
                <input onChange={(e) => setLogin(e.target.value)} className="form-control w-100 mb-2" type="text" placeholder="Login" />
                <input onChange={(e) => setPassword(e.target.value)} className="form-control w-100" type="password" placeholder="Password" />
                <button onClick={handleAdminLogin} className="btn btn-success mt-2 w-100">Login</button>
            </BackPageLoginContainer>
        </BackPageLoginBox>
    )
}


const BackPageLoginBox = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`

const BackPageLoginContainer = styled.div`
    width: 400px;
    min-height: 100px;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
`


export default BackPageLogin;