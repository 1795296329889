import React, { useEffect, useState } from "react";
import Header from "./header";
// import BookImage from '../images/books/book.jpg'
import styled from "styled-components";
import { FaArrowRight } from "react-icons/fa6";
import { IoCalendarOutline } from "react-icons/io5";
// import { FaRegHeart } from "react-icons/fa";
// import { FaHeart } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import axios from "../api/axios";
import { BooksStore } from "../store/books";
import { useDispatch, useSelector } from "react-redux";
import Loader from '../pages/loader';
import Pagination from '@mui/material/Pagination';
import { useTranslation } from "react-i18next";
import axiosInstance from "../api/axios";




const Books = (props) => {
    // const [like, setLike] = useState(true);
    const VarBooks = useSelector((books) => books);
    const dispatch = useDispatch();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [pageNumber, setPageNumber] = useState(0);
    const { t } = useTranslation();

    const BookName = searchParams.get('title');
    const BookTag = searchParams.get('tag')
    const BookAuthor = searchParams.get('Book_Author');
    const BookPublisher = searchParams.get('Publisher');
    const BookUDK = searchParams.get('UDK');
    const BookISBNNumber = searchParams.get('ISBN_No');
    const BookPublisherDate = searchParams.get('Publish_Date');
    const BookCategory = searchParams.get('Category');
    const [loader, setLoader] = useState(false);


    const BookGrid = styled.div`
        display: grid;
        grid-template-columns: repeat(${props.grid}, 1fr);
        grid-gap: 20px;

        @media (max-width: 576px) {
            grid-template-columns: repeat(1, 1fr);
        }
    `

    useEffect(() => {
        axiosInstance.get(`/api/Book?Category=1&pageNumber=0&pageSize=${props?.size}`)
            .then(res => {
                dispatch(BooksStore(res?.data?.result))
                setLoader(true)
            })
            .catch(err => {
                console.log(err);
                setLoader(false);
            })
    }, [])

    useEffect(() => {
        setLoader(false)
        axiosInstance.get(`/api/Book?Title=${BookName}&tag=${BookTag}&Book_Author=${BookAuthor}&Publisher=${BookPublisher}&UDK=${BookUDK}&ISBN_No=${BookISBNNumber}&Book_Inventory_No=&Publish_Date=${BookPublisherDate}&Category=${BookCategory}&pageNumber=${pageNumber}&pageSize=${props?.size}`)
            .then(res => {
                dispatch(BooksStore(res?.data?.result))
                setLoader(true)
            })
            .catch(err => {
                console.log(err);
                setLoader(false);
            })
    }, [BookName, BookTag, BookAuthor, BookPublisher, BookUDK, BookISBNNumber, BookPublisherDate, BookCategory, pageNumber])

    function paginationFunc(e, p) {
        setPageNumber(p - 1);
        setLoader(true);
    }


    return (
        <>
            {props.pagination === 'main' ? 
                <div className="container mt-2">
                    <Header title={t('BOOKS1')} />
                </div>
                :
                ''
            }
            {loader ? 
                <BooksBox>
                    <div className="container">
                        <BookGrid>
                            {VarBooks && VarBooks?.books?.books?.items?.map((item) => {
                                return(
                                    <Link to={`/book/${item?.id}`} style={{color: '#000'}}>
                                        <BookItem data-aos="fade-down" data-aos-delay="0">
                                            <BookImageBox>
                                                {/* <p>
                                                    {like ? <button onClick={() => setLike(false)}><FaRegHeart style={{ color: 'red' }} /></button> : <button onClick={() => setLike(true)}><FaHeart style={{ color: 'red' }} /></button>}
                                                </p> */}
                                                <img src={`${process.env.REACT_APP_BACKEND_URL}api/file/downloadimagefile/${item?.coverImage?.id}/middle`} alt="book_image" />
                                            </BookImageBox>
                                            <BookAbout>
                                                <BookData>
                                                    <p><IoCalendarOutline />{item?.publish_Date}</p>
                                                </BookData>
                                                <BookTitle>
                                                    <h6>{item?.title}</h6>
                                                </BookTitle>
                                                <BookLink>
                                                    <p>{t('BAT')} <FaArrowRight /></p>
                                                </BookLink>
                                            </BookAbout>
                                        </BookItem>
                                    </Link>
                                )
                            })}
                        </BookGrid>
                    </div>
                </BooksBox>
                :
                <Loader />
            }
            {props?.pagination !== 'main' ? 
                <PaginationBox>
                    <Pagination
                        onChange={paginationFunc}
                        count={VarBooks?.books?.books?.totalPages}
                        variant="outlined"
                        shape="rounded"
                    />
                </PaginationBox>
                :
                ''
            }
        </>
    )
}

const BooksBox = styled.div`
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
`

const BookItem = styled.div`
    width: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    position: relative;
    background-color: #fff;
    margin-top: 20px;
    z-index: 10;

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        left: -5px;
        margin: auto;
        width: 110%;
        border-radius: 10px;
        transform: translateX(-5%);
        background: linear-gradient(-45deg, #e81cff 0%, #40c9ff 100% );
        opacity: 0;
        z-index: -10;
        pointer-events: none;
        transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    &::after {
        content: "";
        z-index: -1;
        position: absolute;
        inset: 0;
        transform: translate3d(0, 0, 0) scale(0.95);
        filter: blur(20px);
    }

    &:hover::after {
        filter: blur(30px);
    }

    &:hover::before {
        transform: rotate(-180deg) scaleX(0.95) scaleY(1.05) translateY(+1%) translateX(+3%);
        opacity: 1;
    }

    @media (max-width: 576px) {
        width: 70%;
        margin-left: 60px;
    }
`

const BookImageBox = styled.div`
    width: 100%;
    height: 350px;
    overflow: hidden;
    transition: all 0.1s;
    position: relative;

    img {
        width: 100%;
        transition: all 0.5s;
        border-radius: 10px;
    }

    p {
        position: absolute;
        z-index: 101;
        font-size: 32px;
        top: 0px;
        right: 10px;

        button {
            background-color: transparent;
            border: none;
        }
    }

    &:hover {
        border-radius: 10px;
        img {
            transform: scale(1.1);
            border-radius: 10px;
        }
    }
`

const BookAbout = styled.div`
    width: 100%;
    overflow: hidden;
`

const BookData = styled.div`
    width: 100%;
    height: 20px;
    padding-left: 8px;
    overflow: hidden;
`

const BookTitle = styled.div`
    width: 100%;
    height: 80px;
    padding-left: 8px;
    padding-top: 5px;
    overflow: hidden;
`

const BookLink = styled.div`
    width: 100%;
    height: 30px;
    line-height: 30px;
    padding-left: 8px;
    overflow: hidden;
`

const PaginationBox = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`

export default Books;