import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import axiosInstance from "../api/axios";




const BackPage = () => {
    const [bulidings, setBuildings] = useState([]);
    const [bulding, setBuilding] = useState('');
    const navigate = useNavigate();
    const [role, setRole] = useState(localStorage.getItem('role'))

    useEffect(() => {
        axiosInstance.get('/api/Building')
            .then(res => {
                setBuildings(res?.data?.result?.items)
            })
            .catch(err => {
                console.log(err)
            })
    }, [])


    function handleChangeBuilding(e) {
        setBuilding(e.target.value)
    }

    function handleHome() {
        navigate('/')
    }

    localStorage.setItem('building', bulding);

    return (
        <>
            {role == 2 
                ?
                <BackPageBox>
                    <BackPageContainer>
                        <h1>Binoni tanlang...</h1>
                        <select onChange={handleChangeBuilding}>
                            {bulidings && bulidings.map((item) => {
                                return (
                                    <option value={item?.id}>{item?.name}</option>
                                )
                            })}
                        </select>
                        <button onClick={handleHome} className="btn btn-success w-100 mt-2">Bosh sahifa</button>
                    </BackPageContainer>
                </BackPageBox>
                :
                <BackPageBox>
                    <h1>Bu sahifaga kirishga siz uchun ruxsat yuq</h1>
                </BackPageBox>
            }
        </>
    )
}

const BackPageBox = styled.div`
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`


const BackPageContainer = styled.div`
    width: 400px;
    min-height: 100px;
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
    padding: 10px;

    h1 {
        font-size: 22px;
    }

    select {
        width: 100%;
        padding: 5px 8px;
    }
`

export default BackPage;