import axios from 'axios';
import React, { useState } from 'react'
import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { MdOutlineAddPhotoAlternate } from 'react-icons/md'
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { uploadImageFunc } from '../store/uploadImage';
import axiosInstance from '../api/axios';


export default function UploadImg(props) {
    const [imageId, setImageId] = useState('');
    const dispatch = useDispatch();



    const onDrop = useCallback(acceptedFiles => {
        let formData = new FormData();
        formData.append('File', acceptedFiles[0]);

        axiosInstance.post(`/api/Post/UploadPostImage?type=cover`, formData)
            .then(res => {
                if (props.type === 'main') {
                    dispatch(uploadImageFunc(res?.data?.result?.id));
                    setImageId(res?.data?.result?.fileUrl)
                }
                if (props.type === 'content') {
                    setImageId(res?.data?.result?.fileUrl)
                    navigator.clipboard.writeText(`${process.env.REACT_APP_BACKEND_URL}api/file/downloadimagefile/${res?.data?.result?.id}/middle`)
                }
            })
            .catch(err => {
                console.log(err)
            })

    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })


    return (
        <FrontImage>
            <FrontImageBox {...getRootProps()}>
                <MdOutlineAddPhotoAlternate {...getInputProps()} />
            </FrontImageBox>
            <FrontImageText>
                <p>{imageId ? `${process.env.REACT_APP_BACKEND_URL}${imageId}` : 'Rasmni yulash uchun surub olib keling yoki bosing'}</p>
            </FrontImageText>
        </FrontImage>
    )
}


const FrontImage = styled.div`
    width: 100%;
    height: 100px;
    display: grid;
    grid-template-columns: 1fr 8fr;
    align-items: center;
`

const FrontImageBox = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background-color: #f2f2f2;
    font-size: 32px;
    border-radius: 10px;

    svg {
        display: block !important;
        outline: none;
    }
`


const FrontImageText = styled.div`
    display: flex;
    align-items: center;

    p {
        margin: 0;
        padding: 0;
    }
`