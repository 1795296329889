import React, { useEffect, useState } from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import styled from "styled-components";
import Header from "../components/header";
import { Link } from "react-router-dom";
import axios from "axios";
import { IoCalendarOutline } from "react-icons/io5";
import Loader from "./loader";
// import Pagination from '@mui/material/Pagination';
import { useTranslation } from "react-i18next";
import axiosInstance from "../api/axios";


const News = () => {
    const [posts, setPosts] = useState([]);
    const [loader, setLoader] = useState(false);
    const { t } = useTranslation();



    useEffect(() => {
        axiosInstance.get(`/api/Post`)
            .then(res => {
                setPosts(res?.data?.result?.items)
                setLoader(true);
            })
            .catch(err => {
                console.log(err);
                setLoader(false);
            })
    }, [])

    // function paginationFunc(e, p) {
    //     setPag(p - 1);
    // }


    return (
        <div>
            <div>
                <Navbar />
            </div>
            <div className="container">
                <NewsBox>
                    <Header title={t('POSTS')} />
                    {loader ? 
                        <NewsItems>
                            {posts && posts.map((item) => {
                                return (
                                    <Link to={`/single-post/${item?.id}`} style={{color: '#000'}}>
                                        <NewsItem>
                                            <NewsItemImage>
                                                <img src={`${process.env.REACT_APP_BACKEND_URL}api/file/downloadimagefile/${item?.coverImage?.id}/middle`} alt="news_image" />
                                            </NewsItemImage>
                                            <NewsInformationBox>
                                                <p><IoCalendarOutline /> {new Date(item?.createdAt).toLocaleDateString()}</p>
                                                <h5>{item?.title}</h5>
                                                <Link to={`/single-post/${item?.id}`}>
                                                    {t('BAT')}
                                                </Link>
                                            </NewsInformationBox>
                                        </NewsItem>
                                    </Link>
                                )
                            })}
                        </NewsItems>
                        :
                        <Loader />
                    }
                    {/* <PaginationBox>
                        <Pagination
                            onChange={paginationFunc}
                            count={pagSize?.totalPages}
                            variant="outlined"
                            shape="rounded"
                        />
                    </PaginationBox> */}
                </NewsBox>
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
}


const NewsBox = styled.div`
    margin-top: 85px;
    margin-bottom:200px;
`

const NewsItems = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 10px;

    @media (max-width: 576px) {
        grid-template-columns: repeat(2, 1fr);
        padding: 10px;
    }
`

const NewsItem = styled.div`
    width: 100%;
    box-shadow: 1px 1px 4px 3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    min-height: 350px;
`

const NewsItemImage = styled.div`
    width: 100%;
    height: 150px;
    overflow: hidden;
    transition: all 0.5s;

    img {
        width: 100%;
        transition: all 0.5s;

        &:hover {
            transform: scale(1.1);
        }
    }
`

const NewsInformationBox = styled.div`
    padding: 0px 10px;

    p {
        margin: 0;
        margin-bottom: 2px;
        font-size: 12px;
    }

    h5 {
        font-size: 14px;
    }

    a {
        position: absolute;
        bottom: 5px;
        text-decoration: none;
        color: #000;
        font-size: 12px;
    }
`

const PaginationBox = styled.div`
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
`


export default News;